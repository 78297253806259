import { $api } from "./axios.service";

export class ReviewService {
  static getAll(params) {
    return $api.get("/reviews", { params });
  }

  static approve(body) {
    return $api.post("/reviews/approve", body);
  }

  static delete(id) {
    return $api.delete(`/reviews/${id}`);
  }
}
