import { $api } from "./axios.service";

export class ProductService {
  static getAll(params) {
    return $api.get("/products", { params });
  }

  static getOne(slug, lang) {
    return $api.get(`/products/${slug}`, { params: { lang } });
  }

  static create(body) {
    return $api.post("/products", body);
  }

  static update(body) {
    return $api.put("/products", body);
  }

  static delete(slug) {
    return $api.delete(`/products/${slug}`);
  }
}
