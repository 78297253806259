import {
  AiOutlineAppstore,
  AiOutlineComment,
  AiOutlineDropbox,
  AiOutlineFileText,
  AiOutlineInsertRowLeft,
  AiOutlineMail,
  AiOutlinePicture,
  AiOutlineQuestionCircle,
  AiOutlineShoppingCart,
  AiOutlineTags
} from "react-icons/ai";
import { BiCodeBlock } from "react-icons/bi";
import { MdOutlineFeedback } from "react-icons/md";
import {
  RiBankCard2Line,
  RiContactsBookLine,
  RiInboxUnarchiveLine,
  RiTruckLine
} from "react-icons/ri";

const content = [
  {
    type: "group",
    key: "clients",
    label: "Клиенты",
    roles: ["OWNER", "ADMIN"],
    children: [
      {
        type: "link",
        path: "/orders",
        key: "orders",
        label: "Заказы",
        icon: AiOutlineShoppingCart,
        roles: ["OWNER"]
      },
      {
        type: "link",
        path: "/newsletter",
        key: "newsletter",
        label: "Рассылка",
        icon: AiOutlineMail,
        roles: ["OWNER"]
      },
      {
        type: "link",
        path: "/reviews",
        key: "reviews",
        label: "Отзывы",
        icon: AiOutlineComment,
        roles: ["OWNER", "ADMIN"]
      },
      {
        type: "link",
        path: "/blog",
        key: "blog",
        label: "Блог",
        icon: AiOutlineFileText,
        roles: ["OWNER", "ADMIN"]
      },
      {
        type: "link",
        path: "/feedback",
        key: "feedback",
        label: "Обратная связь",
        icon: MdOutlineFeedback,
        roles: ["OWNER", "ADMIN"]
      }
    ]
  },
  {
    type: "group",
    key: "goods",
    label: "Продукция",
    roles: ["OWNER", "ADMIN"],
    children: [
      {
        type: "link",
        path: "/categories",
        key: "categories",
        label: "Категории",
        icon: AiOutlineAppstore,
        roles: ["OWNER", "ADMIN"]
      },
      {
        type: "link",
        path: "/products",
        key: "products",
        label: "Товары",
        icon: AiOutlineDropbox,
        roles: ["OWNER", "ADMIN"]
      }
    ]
  },
  {
    type: "group",
    key: "content",
    label: "Контент",
    roles: ["OWNER", "ADMIN"],
    children: [
      {
        type: "link",
        path: "/metadata",
        key: "metadata",
        label: "Метаданные",
        icon: BiCodeBlock,
        roles: ["OWNER", "ADMIN"]
      },
      {
        type: "link",
        path: "/faqs",
        key: "faqs",
        label: "FAQ",
        icon: AiOutlineQuestionCircle,
        roles: ["OWNER", "ADMIN"]
      },
      {
        type: "link",
        path: "/contacts",
        key: "contacts",
        label: "Контакты",
        icon: RiContactsBookLine,
        roles: ["OWNER", "ADMIN"]
      },
      {
        type: "link",
        path: "/others",
        key: "others",
        label: "Остальное",
        icon: AiOutlineInsertRowLeft,
        roles: ["OWNER", "ADMIN"]
      },
      {
        type: "link",
        path: "/promocodes",
        key: "promocodes",
        label: "Промокоды",
        icon: AiOutlineTags,
        roles: ["OWNER", "ADMIN"]
      },
      {
        type: "link",
        path: "/banners",
        key: "banners",
        label: "Баннера",
        icon: AiOutlinePicture,
        roles: ["OWNER", "ADMIN"]
      }
    ]
  },
  {
    type: "group",
    key: "delivery",
    label: "Доставка",
    roles: ["OWNER", "ADMIN"],
    children: [
      {
        type: "link",
        path: "/transportants",
        key: "transportants",
        label: "Виды",
        icon: RiTruckLine,
        roles: ["OWNER", "ADMIN"]
      },
      {
        type: "link",
        path: "/parcels",
        key: "parcels",
        label: "Пакоматы",
        icon: RiInboxUnarchiveLine,
        roles: ["OWNER", "ADMIN"]
      },
      {
        type: "link",
        path: "/payments",
        key: "payments",
        label: "Реквизиты",
        icon: RiBankCard2Line,
        roles: ["OWNER", "ADMIN"]
      }
    ]
  }
];

export const navigationConfig = [...content];
