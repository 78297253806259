import { $api } from "./axios.service";

export class MetadataService {
  static getAll(params) {
    return $api.get("/metadata", { params });
  }

  static getOne(slug, lang) {
    return $api.get(`/metadata/${slug}`, { params: { lang } });
  }

  static create(body) {
    return $api.post("/metadata", body);
  }

  static update(body) {
    return $api.put("/metadata", body);
  }

  static delete(slug) {
    return $api.delete(`/metadata/${slug}`);
  }
}
