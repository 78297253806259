import { useEffect, useMemo } from "react";

import { useSearchParams } from "react-router-dom";

export const useUrlParams = ({ defaultParams = {}, ...config }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const params = Object.fromEntries(searchParams);
  const hasParams = useMemo(() => Boolean(Object.keys(params)?.length), [params]);

  const handleParams = (name, param) => {
    setSearchParams({ ...params, [name]: param });
  };

  useEffect(() => {
    if (!hasParams) setSearchParams(defaultParams, { replace: true, ...config });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasParams]);

  return [params, hasParams, handleParams];
};
