import { Suspense, createElement, useEffect, useState } from "react";

import { Button, Divider, Drawer, Layout } from "antd";
import dayjs from "dayjs";
import Scrollbars from "react-custom-scrollbars-2";
import { AiFillLeftCircle, AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import { useSelector } from "react-redux";
import MediaQuery, { useMediaQuery } from "react-responsive";
import { Outlet, useLocation } from "react-router-dom";
import { utils, writeFile } from "xlsx";

import { ErrorBoundary, Logo, Navigation, PageLoader, ProfileMenu } from "@components";

import { getUserState } from "@store/selectors";

import { navigationConfig } from "@configs";

import { ExportService } from "@services";

export const AdminLayout = () => {
  const { user } = useSelector(getUserState);

  const [visible, setVisible] = useState(false);

  const { pathname } = useLocation();

  const isMobile = useMediaQuery({ maxWidth: 1200 });

  useEffect(() => {
    if (visible && isMobile) setVisible(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleVisible = () => setVisible((prev) => !prev);

  const download = async () => {
    const clients = await ExportService.clients();

    const filename = `clients-${dayjs(new Date()).format("DD.MM.YYYY")}.xlsx`;
    const ws = utils.json_to_sheet(
      clients.data.map((client) => {
        const newClient = { ...client, ...client.address };

        delete newClient.address;
        delete newClient._id;

        return newClient;
      })
    );
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Clients");
    writeFile(wb, filename);
  };

  return (
    <Layout className="admin-layout">
      <MediaQuery minWidth={1201}>
        <Layout.Sider
          theme="dark"
          width={250}
          trigger={null}
          collapsible
          collapsed={visible}
          className="admin-layout__sidebar"
        >
          <Scrollbars autoHide>
            <div
              className={
                !visible ? "admin-layout__logo" : "admin-layout__logo admin-layout__logo_collapsed"
              }
            >
              <Logo />
            </div>
            <Divider className="admin-layout__divider" />
            <Navigation items={navigationConfig} />
          </Scrollbars>
        </Layout.Sider>
      </MediaQuery>
      <MediaQuery maxWidth={1200}>
        <Drawer
          width={250}
          className="admin-layout__drawer"
          placement="left"
          closable={false}
          visible={visible}
          onClose={handleVisible}
        >
          <Scrollbars autoHide>
            <div className="admin-layout__drawer-header">
              <Logo />
              <AiFillLeftCircle onClick={handleVisible} size={30} className="admin-layout__close" />
            </div>
            <Divider className="admin-layout__divider" />
            <Navigation items={navigationConfig} />
          </Scrollbars>
        </Drawer>
      </MediaQuery>
      <Layout className="admin-layout__inner">
        <Layout.Header className="admin-layout__header">
          {createElement(visible || isMobile ? AiOutlineMenuUnfold : AiOutlineMenuFold, {
            className: "admin-layout__trigger",
            onClick: handleVisible
          })}
          {user?.role === "OWNER" && (
            <Button
              style={{
                marginLeft: "auto",
                marginRight: "15px"
              }}
              type="primary"
              onClick={download}
            >
              Экспортировать клиентов
            </Button>
          )}

          <ProfileMenu />
        </Layout.Header>
        <Layout.Content>
          <div className="admin-layout__content">
            <ErrorBoundary>
              <Suspense fallback={<PageLoader loading />}>
                <Outlet />
              </Suspense>
            </ErrorBoundary>
          </div>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};
