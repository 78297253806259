import Cookies from "js-cookie";

import { $api } from "./axios.service";

export class AuthService {
  static async login(body) {
    const resp = await $api.post("/auth/login", body, { withCredentials: true });
    if (resp.status === 200) Cookies.set("accessToken", resp.data.token);

    return resp;
  }

  static async refresh() {
    const resp = await $api.post("/auth/refresh", {}, { withCredentials: true });
    if (resp.status === 200) Cookies.set("accessToken", resp.data.token);

    return resp;
  }

  static async logout() {
    await $api.post("/auth/logout", {}, { withCredentials: true });
    Cookies.remove("accessToken");
  }

  static getUserInfo() {
    return $api.get("/auth/user");
  }

  static changePassword(body) {
    return $api.post("/auth/change-password", body, { withCredentials: true });
  }

  static inviteCreate(body) {
    return $api.post("/auth/invite-create", body, { withCredentials: true });
  }

  static inviteConfirm(body) {
    return $api.post("/auth/invite-confirm", body);
  }

  static adminList() {
    return $api.get("/auth/admin-list");
  }

  static removeAccess(id) {
    return $api.delete(`/auth/${id}`);
  }
}
