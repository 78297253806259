import { $api } from "./axios.service";

export class PaymentService {
  static getAll(params) {
    return $api.get("/payments", { params });
  }

  static getOne(id) {
    return $api.get(`/payments/${id}`);
  }

  static create(body) {
    return $api.post("/payments", body);
  }

  static update(id, body) {
    return $api.put(`/payments/${id}`, body);
  }

  static delete(id) {
    return $api.delete(`/payments/${id}`);
  }
}
