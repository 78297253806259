import { $api } from "./axios.service";

export class OrderService {
  static getAll(params) {
    return $api.get("/orders", { params });
  }

  static getOne(id) {
    return $api.get(`/orders/${id}`);
  }

  static sendTrack(body) {
    return $api.post("/orders/send-track", body);
  }

  static update(id, body) {
    return $api.put(`/orders/${id}`, body);
  }

  static delete(id) {
    return $api.delete(`/orders/${id}`);
  }
}
