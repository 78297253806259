import { $api } from "./axios.service";

export class OthersService {
  static getAll(params) {
    return $api.get("/others", { params });
  }

  static getOne(slug, lang) {
    return $api.get(`/others/${slug}`, { params: { lang } });
  }

  static create(body) {
    return $api.post("/others", body);
  }

  static update(body) {
    return $api.put("/others", body);
  }

  static delete(slug) {
    return $api.delete(`/others/${slug}`);
  }
}
