// import { editorConfig } from "@configs";
import Editor from "./editor";

export const TextEditor = ({ value, onChange }) => (
  // <Editor
  //   apiKey={process.env.REACT_APP_EDITOR_API_KEY}
  //   init={editorConfig}
  //   value={value}
  //   onEditorChange={onChange}
  // />

  <Editor value={value} onChange={onChange} />
);
