import { $api } from "./axios.service";

export class BannerService {
  static getAll() {
    return $api.get("/images");
  }

  static getOne(id) {
    return $api.get(`/images/${id}`);
  }

  static create(body) {
    return $api.post("/images", body);
  }

  static update(id, body) {
    return $api.put(`/images/${id}`, body);
  }

  static delete(id) {
    return $api.delete(`/images/${id}`);
  }
}
