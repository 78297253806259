import { $api } from "./axios.service";

export class NewsletterService {
  static send(data) {
    return $api.post("/newsletter", data);
  }

  static getSubsribers(params) {
    return $api.get("/newsletter/subscribers", { params });
  }
}
