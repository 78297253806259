import { $api } from "./axios.service";

export class ContactService {
  static getOne(lang) {
    return $api.get("/contacts", { params: { lang } });
  }

  static update(body) {
    return $api.put("/contacts", body);
  }
}
