import { $api } from "./axios.service";

export class BlogService {
  static getAll(params) {
    return $api.get("/blog", { params });
  }

  static getOne(slug, lang) {
    return $api.get(`/blog/${slug}`, { params: { lang } });
  }

  static create(body) {
    return $api.post("/blog", body);
  }

  static update(body) {
    return $api.put("/blog", body);
  }

  static delete(slug) {
    return $api.delete(`/blog/${slug}`);
  }
}
