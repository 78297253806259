import { Dropdown, Menu, message } from "antd";
import { AiOutlineLogout, AiOutlineMail } from "react-icons/ai";
import { BsKey } from "react-icons/bs";
import { HiUserCircle } from "react-icons/hi";
import { RiAdminFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getUserState } from "@store/selectors";

import { AuthService } from "@services";

import { useActions } from "@hooks";

export const ProfileMenu = () => {
  const { user } = useSelector(getUserState);

  const { setUserInfo, setIsAuthorized } = useActions();

  const navigate = useNavigate();

  const handleLogout = async () => {
    await AuthService.logout();
    setUserInfo(null);
    setIsAuthorized(false);

    navigate("/auth/login");
    message.success("Вы вышли 😒");
  };

  return (
    <Dropdown
      overlay={
        <Menu
          className="profile-menu"
          items={
            user?.role === "OWNER"
              ? [
                  {
                    key: "admins",
                    label: <div onClick={() => navigate("/settings/admins")}>Администраторы</div>,
                    icon: <RiAdminFill />
                  },
                  {
                    key: "invite",
                    label: <div onClick={() => navigate("/settings/invite")}>Пригласить</div>,
                    icon: <AiOutlineMail />
                  },
                  {
                    key: "change-password",
                    label: (
                      <div onClick={() => navigate("/settings/change-password")}>
                        Изменить пароль
                      </div>
                    ),
                    icon: <BsKey />
                  },
                  {
                    key: "logout",
                    label: <div onClick={handleLogout}>Выйти</div>,
                    icon: <AiOutlineLogout />
                  }
                ]
              : [
                  {
                    key: "change-password",
                    label: (
                      <div onClick={() => navigate("/settings/change-password")}>
                        Изменить пароль
                      </div>
                    ),
                    icon: <BsKey />
                  },
                  {
                    key: "logout",
                    label: <div onClick={handleLogout}>Выйти</div>,
                    icon: <AiOutlineLogout />
                  }
                ]
          }
        />
      }
      trigger={["click"]}
    >
      <HiUserCircle className="profile-menu__icon" />
    </Dropdown>
  );
};
