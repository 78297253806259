import { $api } from "./axios.service";

export class ParcelService {
  static getAll(params) {
    return $api.get("/parcels", { params });
  }

  static getOne(id) {
    return $api.get(`/parcels/${id}`);
  }

  static create(body) {
    return $api.post("/parcels", body);
  }

  static update(id, body) {
    return $api.put(`/parcels/${id}`, body);
  }

  static delete(id) {
    return $api.delete(`/parcels/${id}`);
  }
}
