import { $api } from "./axios.service";

export class FaqService {
  static getAll(params) {
    return $api.get("/faqs", { params });
  }

  static getOne(slug, lang) {
    return $api.get(`/faqs/${slug}`, { params: { lang } });
  }

  static create(body) {
    return $api.post("/faqs", body);
  }

  static update(body) {
    return $api.put("/faqs", body);
  }

  static delete(slug) {
    return $api.delete(`/faqs/${slug}`);
  }
}
