import { lazy } from "react";

export const DEFAULT_ROUTE = (role) => (role === "OWNER" ? "orders" : "reviews");

export const adminRoutes = [
  // Blog
  {
    path: "blog",
    component: lazy(() => import("@pages/admin/blog")),
    roles: ["OWNER", "ADMIN"]
  },
  {
    path: "blog/create",
    component: lazy(() => import("@pages/admin/blog/create")),
    roles: ["OWNER", "ADMIN"]
  },
  {
    path: "blog/edit/:slug",
    component: lazy(() => import("@pages/admin/blog/edit")),
    roles: ["OWNER", "ADMIN"]
  },

  // Categories
  {
    path: "categories",
    component: lazy(() => import("@pages/admin/categories")),
    roles: ["OWNER", "ADMIN"]
  },
  {
    path: "categories/create",
    component: lazy(() => import("@pages/admin/categories/create")),
    roles: ["OWNER", "ADMIN"]
  },
  {
    path: "categories/edit/:slug",
    component: lazy(() => import("@pages/admin/categories/edit")),
    roles: ["OWNER", "ADMIN"]
  },

  // Contacts
  {
    path: "contacts",
    component: lazy(() => import("@pages/admin/contacts")),
    roles: ["OWNER", "ADMIN"]
  },
  {
    path: "contacts/edit",
    component: lazy(() => import("@pages/admin/contacts/edit")),
    roles: ["OWNER", "ADMIN"]
  },

  // Faqs
  {
    path: "faqs",
    component: lazy(() => import("@pages/admin/faqs")),
    roles: ["OWNER", "ADMIN"]
  },
  {
    path: "faqs/create",
    component: lazy(() => import("@pages/admin/faqs/create")),
    roles: ["OWNER", "ADMIN"]
  },
  {
    path: "faqs/edit/:slug",
    component: lazy(() => import("@pages/admin/faqs/edit")),
    roles: ["OWNER", "ADMIN"]
  },

  // Metadata
  {
    path: "metadata",
    component: lazy(() => import("@pages/admin/metadata")),
    roles: ["OWNER", "ADMIN"]
  },
  {
    path: "metadata/create",
    component: lazy(() => import("@pages/admin/metadata/create")),
    roles: ["OWNER", "ADMIN"]
  },
  {
    path: "metadata/edit/:slug",
    component: lazy(() => import("@pages/admin/metadata/edit")),
    roles: ["OWNER", "ADMIN"]
  },

  // // Orders
  {
    path: "orders",
    component: lazy(() => import("@pages/admin/orders")),
    roles: ["OWNER"]
  },
  {
    path: "orders/edit/:id",
    component: lazy(() => import("@pages/admin/orders/edit")),
    roles: ["OWNER"]
  },

  // Parcels
  {
    path: "parcels",
    component: lazy(() => import("@pages/admin/parcels")),
    roles: ["OWNER", "ADMIN"]
  },
  {
    path: "parcels/create",
    component: lazy(() => import("@pages/admin/parcels/create")),
    roles: ["OWNER", "ADMIN"]
  },
  {
    path: "parcels/edit/:id",
    component: lazy(() => import("@pages/admin/parcels/edit")),
    roles: ["OWNER", "ADMIN"]
  },

  // Payments
  {
    path: "payments",
    component: lazy(() => import("@pages/admin/payments")),
    roles: ["OWNER", "ADMIN"]
  },
  {
    path: "payments/create",
    component: lazy(() => import("@pages/admin/payments/create")),
    roles: ["OWNER", "ADMIN"]
  },
  {
    path: "payments/edit/:id",
    component: lazy(() => import("@pages/admin/payments/edit")),
    roles: ["OWNER", "ADMIN"]
  },

  // Product
  {
    path: "products",
    component: lazy(() => import("@pages/admin/products")),
    roles: ["OWNER", "ADMIN"]
  },
  {
    path: "products/create",
    component: lazy(() => import("@pages/admin/products/create")),
    roles: ["OWNER", "ADMIN"]
  },
  {
    path: "products/edit/:slug",
    component: lazy(() => import("@pages/admin/products/edit")),
    roles: ["OWNER", "ADMIN"]
  },

  // Promocodes
  {
    path: "promocodes",
    component: lazy(() => import("@pages/admin/promocodes")),
    roles: ["OWNER", "ADMIN"]
  },
  {
    path: "promocodes/create",
    component: lazy(() => import("@pages/admin/promocodes/create")),
    roles: ["OWNER", "ADMIN"]
  },
  {
    path: "promocodes/edit/:id",
    component: lazy(() => import("@pages/admin/promocodes/edit")),
    roles: ["OWNER", "ADMIN"]
  },

  // Reviews
  {
    path: "reviews",
    component: lazy(() => import("@pages/admin/reviews")),
    roles: ["OWNER", "ADMIN"]
  },

  // Transportants
  {
    path: "transportants",
    component: lazy(() => import("@pages/admin/transportants")),
    roles: ["OWNER", "ADMIN"]
  },
  {
    path: "transportants/create",
    component: lazy(() => import("@pages/admin/transportants/create")),
    roles: ["OWNER", "ADMIN"]
  },
  {
    path: "transportants/edit/:id",
    component: lazy(() => import("@pages/admin/transportants/edit")),
    roles: ["OWNER", "ADMIN"]
  },

  // Other
  {
    path: "others",
    component: lazy(() => import("@pages/admin/others")),
    roles: ["OWNER", "ADMIN"]
  },
  {
    path: "others/create",
    component: lazy(() => import("@pages/admin/others/create")),
    roles: ["OWNER", "ADMIN"]
  },
  {
    path: "others/edit/:slug",
    component: lazy(() => import("@pages/admin/others/edit")),
    roles: ["OWNER", "ADMIN"]
  },

  // Banners
  {
    path: "banners",
    component: lazy(() => import("@pages/admin/banners")),
    roles: ["OWNER", "ADMIN"]
  },
  {
    path: "banners/create",
    component: lazy(() => import("@pages/admin/banners/create")),
    roles: ["OWNER", "ADMIN"]
  },
  {
    path: "banners/edit/:id",
    component: lazy(() => import("@pages/admin/banners/edit")),
    roles: ["OWNER", "ADMIN"]
  },

  // Newsletter
  {
    path: "newsletter",
    component: lazy(() => import("@pages/admin/newsletter")),
    roles: ["OWNER"]
  },

  // Feedback
  {
    path: "feedback",
    component: lazy(() => import("@pages/admin/feedback")),
    roles: ["OWNER", "ADMIN"]
  },

  // Settings
  {
    path: "settings/admins",
    component: lazy(() => import("@pages/admin/settings/admins")),
    roles: ["OWNER"]
  },
  {
    path: "settings/invite",
    component: lazy(() => import("@pages/admin/settings/invite")),
    roles: ["OWNER"]
  },
  {
    path: "settings/change-password",
    component: lazy(() => import("@pages/admin/settings/change-password")),
    roles: ["OWNER", "ADMIN"]
  }
];
