import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Alignment,
  AutoImage,
  AutoLink,
  Bold,
  ClassicEditor,
  Essentials,
  FindAndReplace,
  Heading,
  Image,
  ImageCaption,
  ImageInsert,
  ImageResize,
  ImageResizeEditing,
  ImageResizeHandles,
  ImageStyle,
  ImageToolbar,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  MediaEmbed,
  Mention,
  Paragraph,
  RemoveFormat,
  SelectAll,
  ShowBlocks,
  SourceEditing,
  SpecialCharacters,
  SpecialCharactersEssentials,
  Strikethrough,
  Table,
  TableColumnResize,
  TableToolbar,
  Underline,
  Undo
} from "ckeditor5";

const Editor = (props) => {
  // **Props
  const { value, onChange } = props;

  return (
    <CKEditor
      editor={ClassicEditor}
      data={value || ""}
      onChange={(_, editor) => {
        onChange(editor.getData());
      }}
      config={{
        toolbar: {
          items: [
            "undo",
            "redo",
            "|",
            "showBlocks",
            "sourceEditing",
            "|",
            "heading",
            "|",
            "bold",
            "italic",
            "strikethrough",
            "underline",
            "|",
            "link",
            "insertTable",
            "insertImage",
            "mediaEmbed",
            "specialCharacters",
            "|",
            "bulletedList",
            "numberedList",
            "alignment",
            "|",
            "findAndReplace",
            "removeFormat"
          ]
        },

        plugins: [
          Bold,
          Essentials,
          Italic,
          Mention,
          Paragraph,
          Undo,
          Heading,
          Link,
          RemoveFormat,
          ShowBlocks,
          SourceEditing,
          SpecialCharacters,
          SpecialCharactersEssentials,
          Alignment,
          Strikethrough,
          Underline,
          FindAndReplace,
          Image,
          ImageInsert,
          ImageToolbar,
          ImageCaption,
          ImageStyle,
          ImageResize,
          LinkImage,
          ImageResizeEditing,
          ImageResizeHandles,
          AutoImage,
          AutoLink,
          List,
          ListProperties,
          MediaEmbed,
          SelectAll,
          Table,
          TableToolbar,
          TableColumnResize
        ],
        list: {
          properties: {
            styles: true,
            startIndex: true,
            reversed: true
          }
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph"
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1"
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2"
            },
            {
              model: "heading3",
              view: "h3",
              title: "Heading 3",
              class: "ck-heading_heading3"
            },
            {
              model: "heading4",
              view: "h4",
              title: "Heading 4",
              class: "ck-heading_heading4"
            },
            {
              model: "heading5",
              view: "h5",
              title: "Heading 5",
              class: "ck-heading_heading5"
            },
            {
              model: "heading6",
              view: "h6",
              title: "Heading 6",
              class: "ck-heading_heading6"
            }
          ]
        },
        image: {
          toolbar: [
            "resizeImage",
            "|",
            "imageStyle:block",
            "imageStyle:side",
            "|",
            "toggleImageCaption",
            "imageTextAlternative",
            "|",
            "linkImage"
          ],
          insert: {
            integrations: ["url"]
          }
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableProperties",
            "tableCellProperties"
          ]
        }
      }}
    />
  );
};

export default Editor;
