import { $api } from "./axios.service";

export class FeedbackService {
  static getAll(params) {
    return $api.get("/feedbacks", { params });
  }

  static delete(id) {
    return $api.delete(`/feedbacks/${id}`);
  }
}
