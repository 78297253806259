import { useMemo } from "react";

import { Menu } from "antd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { DEFAULT_ROUTE } from "@routes";

import { getUserState } from "@store/selectors";

export const Navigation = ({ items }) => {
  const { user } = useSelector(getUserState);

  const customItems = useMemo(() => {
    const mapItems = (childs) =>
      childs.map((child) => {
        if (child?.roles && !child?.roles?.includes(user?.role)) return;

        const label =
          child.type === "link" ? <Link to={child.path || "/"}>{child.label}</Link> : child.label;

        const icon = child.icon && <child.icon />;
        const children = child.children && mapItems(child.children)?.filter(Boolean);

        return {
          ...child,
          label,
          icon,
          children
        };
      });

    return mapItems(items)?.filter(Boolean);
  }, [items, user]);

  return (
    <Menu
      theme="dark"
      defaultSelectedKeys={[DEFAULT_ROUTE(user?.role)]}
      mode="inline"
      items={customItems}
      className="navigation"
    />
  );
};
