export const TRANSPORTANT_TYPES = [
  {
    value: "ADDRESS",
    label: "На адрес"
  },
  {
    value: "PARCEL",
    label: "Пакомат"
  }
];

export const TRANSPORTANTS = ["DPD", "Omniva"];
