/* eslint-disable no-undefined */
import { Card, Upload } from "antd";

import { BucketService } from "@services";

import { useRequests } from "@hooks";

export const FileUpload = ({ label, value, onChange, multiple, maxCount, onRemove }) => {
  const { handleRequest } = useRequests();

  const beforeUpload = () => false;

  const handleRemove = handleRequest(async (file) => {
    if (!file || !file?.id) return;

    if (onRemove) onRemove(file);

    if (!value?.isPreloaded) return;

    const resp = await BucketService.delete(file.id);
    if (resp.status !== 200) throw resp;
  });

  const handleChange = ({ fileList }) => {
    if (!multiple) {
      if (!value) {
        onChange(fileList[0]);
      } else {
        onChange(fileList[1]);
        handleRemove(fileList[0]);
      }
    } else {
      onChange(fileList);
    }
  };

  const getFileList = () => {
    if (!value) return undefined;

    if (Array.isArray(value)) return value;
    else return [value];
  };

  return (
    <Card type="inner" title={label}>
      <Upload
        maxCount={maxCount}
        listType="picture-card"
        fileList={getFileList()}
        onChange={handleChange}
        beforeUpload={beforeUpload}
        onRemove={handleRemove}
        multiple={multiple}
        showUploadList={{
          showRemoveIcon: !!(Array.isArray(value) && value.length > 1)
        }}
      >
        <div>Загрузить</div>
      </Upload>
    </Card>
  );
};
