import { $api } from "./axios.service";

export class TransportantService {
  static getAll(params) {
    return $api.get("/transportants", { params });
  }

  static getOne(id) {
    return $api.get(`/transportants/${id}`);
  }

  static create(body) {
    return $api.post("/transportants", body);
  }

  static update(id, body) {
    return $api.put(`/transportants/${id}`, body);
  }

  static delete(id) {
    return $api.delete(`/transportants/${id}`);
  }
}
