import { $api } from "./axios.service";

export class BucketService {
  static upload(entity, files) {
    return $api.post(`/bucket/${entity}`, files);
  }

  static delete(id) {
    return $api.delete(`/bucket/${id}`);
  }
}
