import { $api } from "./axios.service";

export class PromocodeService {
  static getAll(params) {
    return $api.get("/promocodes", { params });
  }

  static getOne(id) {
    return $api.get(`/promocodes/${id}`);
  }

  static create(body) {
    return $api.post("/promocodes", body);
  }

  static generate(count) {
    return $api.post("/promocodes/generate", { count });
  }

  static update(id, body) {
    return $api.put(`/promocodes/${id}`, body);
  }

  static delete(id) {
    return $api.delete(`/promocodes/${id}`);
  }
}
